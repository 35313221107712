import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from '../components/header/hero'
import Container from '../components/container'
import Newsletter from '../components/footer/newsletter'

export default ({ data }) => {
  const {pg} = data
  const {acf} = pg
  return (
    <Layout mode="dark">
      <SEO title={pg.yoast_title} meta={pg.yoast_meta} path={pg.slug} />
        <style>{`#hero {min-height: 30vh;}`}</style>
        <Hero
          mode="default"
          title={acf.header_text}
          content=""
          image={ pg.featured_media ? pg.featured_media.localFile.childImageSharp.fluid : null }
          button={{
            title: !!acf?.header_button?.title ? acf.header_button.title : '',
            url: !!acf?.header_button?.url ? acf.header_button.url : '',
            target: !!acf?.header_button?.target ? acf.header_button.target : '',
          }}
        />
        
          <div className="rules">
              <div className="rules__container--header">
                  <h3>{acf.rules_of_eligibility.terms_header}</h3>
              </div>
              <div className="rules__container--content">
                  <div dangerouslySetInnerHTML={{ __html: acf.rules_of_eligibility.rule_1}} />
                  <div dangerouslySetInnerHTML={{ __html: acf.rules_of_eligibility.rule_2}} />
              </div>
              <div className="terms__container--header">
                  <h3>{acf.rules_of_eligibility.rules_header}</h3>
              </div>
              <div className="rules__container__extended--content">
                  <div dangerouslySetInnerHTML={{ __html: acf.rules_of_eligibility.rule_4}} />
                  <div dangerouslySetInnerHTML={{ __html: acf.rules_of_eligibility.rule_5}} />
                  <div dangerouslySetInnerHTML={{ __html: acf.rules_of_eligibility.rule_6}} />
              </div>
              <div className="terms__container--header">
                  <h3>{acf.rules_of_eligibility.terms_header}</h3>
              </div>
              <div className="terms__container--content">
                  <div dangerouslySetInnerHTML={{ __html: acf.rules_of_eligibility.terms_condition}} />
              </div>
          </div>

          <Container>
            <div style={{textAlign: 'center', margin: '50px 0'}}>
              <h3>Questions?</h3>
              <p>
                Contact <a href="mailto:scholarship@mtafoundation.org" target="_blank">scholarship@mtafoundation.org</a> for more information.
              </p>
            </div>
          </Container>

        <Newsletter />
    </Layout>
  )
}
export const pageQuery = graphql`
query ScholarshipTermsQuery {
  pg: wordpressPage(wordpress_id: {eq: 1057}) {
    title
    slug
    acf {
      header_text
      header_button {
        url
        title
        target
      }
      rules_of_eligibility {
        rules_header
        rule_1
        rule_2
        rule_3
        rules_header
        rule_4
        rule_5
        rule_6
        terms_header
        terms_condition
      }
    }
    yoast_meta {
      name
      property
    }
    yoast_title
    featured_media {
      source_url
      localFile {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1980) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  }
}
`